import { Page } from "static/js/app/modules/page";
import * as Models from "static/js/app/models/__index";
import * as Api from "static/js/app/api/endpoints";
import { SearchPager } from "static/js/app/modules/searchPager";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import VehicleStringFormatting from "static/js/app/utils/stringFormatting/vehicleStringFormatting";
import { buildVehicleImageThumbnails, buildVehicleImageThumbnailsSlick } from "static/js/app/templateHelpers/vehicleThumbnailTemplate";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import VehicleFinanceChecker from "themes/module_finance_plugin/static/js/vehice_finance_checker";
import { QuickReplace } from "static/js/app/utils/QuickReplace";
import DOMPurify from "dompurify";
import Common from "static/js/app/pages/common";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import { CombinedSortTerm } from "static/js/app/models/sortTerm";
import { VehicleImageUrls } from "static/js/app/models/__index";


export default class ListingsHelpers {

public static removeEmptySpecListEls(specListEls: NodeListOf<Element>) {
    if(specListEls != null) {

        [].forEach.call(specListEls,(specListEl: Element)=>
        {
        let blankEls: Element[] = [];

        [].forEach.call(specListEl.children, (specEl: Element) => {
            const valEls = specEl.getElementsByClassName("field-value");
            if(valEls.length) {
                if(valEls[0].innerHTML == "" || valEls[0].innerHTML == "null") {
                    blankEls.push(specEl);
                }
            }
        });
        [].forEach.call(blankEls, (el: HTMLElement) => { specListEl.removeChild(el); });
        });
    }
}
public static configureSearchFilterEvents()
{
    const searchFiltersForm = $("#searchFilters:first");
    if (searchFiltersForm !=null && searchFiltersForm.length > 0)
    {
            searchFiltersForm.on("submit",
                (evt) => {
                    ListingsHelpers.RemoveBlanksThenSubmit();
                    return true;
                });
                [].forEach.call(searchFiltersForm[0].querySelectorAll(".sort-dropdown"),(dropdownEl: HTMLInputElement)=>
                {
                    dropdownEl.onchange = (evt) => {ListingsHelpers.RemoveBlanksThenSubmit()};
                }
            );
    }
}
private static RemoveBlanksThenSubmit()
{
    const searchFiltersForm = $<HTMLFormElement>("#searchFilters:first");
    if (searchFiltersForm == null || searchFiltersForm.length == 0)
    {
        return;
    }
    let minPriceEl = searchFiltersForm[0].querySelector('input[name="minprice"]') as HTMLInputElement;
    let maxPriceEl = searchFiltersForm[0].querySelector('input[name="maxprice"]') as HTMLInputElement;
    let minmonthlyPriceEl = searchFiltersForm[0].querySelector('input[name="minmonthlypayment"]') as HTMLInputElement;
    let maxmonthlyPriceEl = searchFiltersForm[0].querySelector('input[name="maxmonthlypayment"]') as HTMLInputElement;

    if(minPriceEl.value == "") { searchFiltersForm[0].removeChild(minPriceEl); }
    if(maxPriceEl.value == "") { searchFiltersForm[0].removeChild(maxPriceEl); }
    if(minmonthlyPriceEl.value == "") { searchFiltersForm[0].removeChild(minmonthlyPriceEl); }
    if(maxmonthlyPriceEl.value == "") { searchFiltersForm[0].removeChild(maxmonthlyPriceEl); }
    searchFiltersForm[0].submit();
}

public static getSortOrder(defaultSortBy: string, defaultSortOrder: string): CombinedSortTerm 
{
    if (Page.queryString["sort-by"] !=null && Page.queryString["sort-by"].indexOf("-") != -1)
    {
        const sortParts = Page.queryString["sort-by"].split("-");
        return  new CombinedSortTerm(sortParts[0],sortParts[1]);;
    }
    else{
       return new CombinedSortTerm(Page.queryString["sort-by"] || defaultSortBy, Page.queryString["order"] ||  defaultSortOrder);
    }
}


public static getSortTerms(sortBy: string, order: string): [{ fieldName: string, isDescending: boolean }] {
    let descending = (order === 'desc');

    return [{ fieldName: sortBy, isDescending: descending }];
}


public static getSearchTerms(): Models.SearchTerm[] {
    var vehTypeMaybePlural = (Page.queryString['vehicletype'] as string);
    var vehTypeSingular = (
        (vehTypeMaybePlural != undefined && vehTypeMaybePlural != null && vehTypeMaybePlural.length > 0) &&
        (vehTypeMaybePlural[vehTypeMaybePlural.length - 1].toLowerCase() == "s"))
        ? vehTypeMaybePlural.substr(0, vehTypeMaybePlural.length - 1)
        : vehTypeMaybePlural;

    return [
        { fieldName: 'vehicle_type', targetValue: vehTypeSingular },
        { fieldName: 'manufacturer', targetValue: Page.queryString["make"] },
        { fieldName: 'model', targetValue: Page.queryString["model"] },
        { fieldName: 'body_type', targetValue: Page.queryString["body"] },
        { fieldName: 'basic_colour', targetValue: Page.queryString["colour"] },
        { fieldName: 'fuel_type', targetValue: Page.queryString["fueltype"] },
        { fieldName: 'gearbox', targetValue: Page.queryString["gearbox"] },
        { fieldName: 'transmission', targetValue: Page.queryString["gearboxtype"] },
        { fieldName: 'berth', targetValue: (Page.queryString["berth"]?.length) ? parseInt(Page.queryString["berth"]) : null },
        { fieldName: 'doors', targetValue: (Page.queryString["doors"]?.length) ? parseInt(Page.queryString["doors"]) : null },
        { fieldName: 'engine', targetValue: Page.queryString["engine"] },
        { fieldName: 'insurance_group', targetValue: Page.queryString["insurance-group"] },
        { fieldName: 'keywords', targetValue: Page.queryString["keywords"] },
        { fieldName: 'year_built', targetValue: (Page.queryString["year"]?.length) ? parseInt(Page.queryString["year"]) : null },
        { fieldName: 'vehicles', targetValue: Page.queryString["vehicles"] },
        { fieldName: 'branch', targetValue: (Page.queryString["branch"]?.length) ? parseFloat(Page.queryString["branch"]) : null },

        {
            fieldName: 'mpg',
            targetRangeMin: (Page.queryString["min-mpg"] != null) ? parseFloat(Page.queryString["min-mpg"]) : null,
            targetRangeMax: (Page.queryString["max-mpg"] != null) ? parseFloat(Page.queryString["max-mpg"]) : null,
        },

        {
            fieldName: 'length',
            targetRangeMin: (Page.queryString["min-length"] != null) ? parseFloat(Page.queryString["min-length"]) : null,
            targetRangeMax: (Page.queryString["max-length"] != null) ? parseFloat(Page.queryString["max-length"]) : null,
        },

        {
            fieldName: 'unladened_weight',
            targetRangeMin: (Page.queryString["min-unladen-weight"] != null) ? parseFloat(Page.queryString["min-unladen-weight"]) : null,
            targetRangeMax: (Page.queryString["max-unladen-weight"] != null) ? parseFloat(Page.queryString["max-unladen-weight"]) : null,
        },

        {
            fieldName: 'engine_capacity',
            targetRangeMin: (Page.queryString["min-engine-size"] != null) ? parseInt(Page.queryString["min-engine-size"]) : null,
            targetRangeMax: (Page.queryString["max-engine-size"] != null) ? parseInt(Page.queryString["max-engine-size"]) : null,
        },
        {
            fieldName: 'price',
            targetRangeMin: (Page.queryString["minprice"] != null) ? parseInt(Page.queryString["minprice"]) : null,
            targetRangeMax: (Page.queryString["maxprice"] != null) ? parseInt(Page.queryString["maxprice"]) : null,
        },
        {
            fieldName: 'finance_quotes.monthly_payment',
            targetRangeMin: (Page.queryString["minmonthlypayment"] != null) ? parseInt(Page.queryString["minmonthlypayment"]) : null,
            targetRangeMax: (Page.queryString["maxmonthlypayment"] != null) ? parseInt(Page.queryString["maxmonthlypayment"]) : null,
        }
    ];
}

public static setSortByOptions(sortBy: string, itemsPerPage: number, order: string) {
    let searchFiltersEl = $('#searchFilters');
    let sortBySelectEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="sort-by"] option');
    let itemsPerPageSelectEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="items-per-page"] option');
   // let orderSelectEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="order"] option');
   
    var optionsAndSelectedValues = [
        { options: sortBySelectEl, selectedValue: `${sortBy}-${order}` },
        { options: itemsPerPageSelectEl, selectedValue: itemsPerPage },
        //{ options: orderSelectEl, selectedValue: order }
    ];

    for (var i = 0; i < optionsAndSelectedValues.length; i++) {
        var options = optionsAndSelectedValues[i].options;
        var selectedValue = optionsAndSelectedValues[i].selectedValue;

        if (selectedValue != undefined && selectedValue !== null) {
            for (var oi = 0; oi < options.length; oi++) {
                let optionEl = options[oi] as HTMLOptionElement;
                if (selectedValue == optionEl.value) {
                    optionEl.selected = true;
                }
            }
        }
    }
}


public static getPageTitle(searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string, prefix: string = "Used ", suffix: string = " For Sale",): string {
    if (searchPerformed.availability == "sold") {
        return "Sold Gallery";
    }

    return ListingsHelpers.getFullSeoVehicleTitleText(searchPerformed,defaultVehicleTypeDisplay, prefix, suffix);
}
public static getFullSeoVehicleTitleText(searchPerformed: Models.VehicleSearchPerformed,defaultVehicleTypeDisplay: string, prefix?: string, suffix?: string, ): string {
    const searchParts = [
        searchPerformed.gearboxDisplay,
        searchPerformed.fuelTypeDisplay,
        searchPerformed.basicColourDisplay,
        searchPerformed.manufacturerDisplay,
        searchPerformed.modelDisplay,
        searchPerformed.bodyTypeDisplay,
        searchPerformed.vehicleTypeDisplay
    ];

    const nonEmptySearchParts = searchParts.filter(s => s !== undefined && s !== null && s.length > 0);

    return (nonEmptySearchParts.length != 0)
        ? (prefix ?? "") + nonEmptySearchParts.filter(s => s.length).join(" ") + (suffix ?? "")
        : (prefix ?? "") + defaultVehicleTypeDisplay + (suffix ?? "");
}

public static getPageMetaDescription(seoTown: string, seoCounty: string, siteTitle: string, searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string): string {
    const seoVehicleTitleText = ListingsHelpers.getFullSeoVehicleTitleText(searchPerformed, defaultVehicleTypeDisplay);

    const prefix = "Check out our " + ((searchPerformed.availability == "sold") ? "previously sold" : (searchPerformed.vehicleStatus == "new") ?"new": "used");

    const notEmpty = (s: string) => (s !== undefined && s !== null && s.length > 0);
    const seoLocation = [seoTown, seoCounty]
        .filter(notEmpty)
        .join(', ');

    const suffix = ((searchPerformed.availability == "sold") ? `at ${siteTitle} in ${seoLocation}` : `for sale in ${seoLocation} at ${siteTitle} here now`);

    return `${prefix} ${seoVehicleTitleText} ${suffix}`;
}

public static transformTemplatedSeoPageMetaDescription(searchPerformed: Models.VehicleSearchPerformed,defaultVehicleTypeDisplay: string,  templatedSeoPageMetaDescription: string): string {
    const seoVehicleTitleText = ListingsHelpers.getFullSeoVehicleTitleText(searchPerformed, defaultVehicleTypeDisplay  );

    return templatedSeoPageMetaDescription.replace("%search_derivative%", seoVehicleTitleText);
}

public static transformTemplatedSeoPageTitle(searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string, templatedSeoPageTitle: string): string {
    const seoVehicleTitleText = ListingsHelpers.getFullSeoVehicleTitleText(searchPerformed,defaultVehicleTypeDisplay);

    return templatedSeoPageTitle.replace("%search_derivative%", seoVehicleTitleText);
}

public static updatePageMetaDescription(seoTown: string, seoCounty: string, siteTitle: string, searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string) {
    const metaDescriptionEl = document.querySelector('meta[name="description"]') as HTMLMetaElement; // .attr('content');
    const metaTagIsSeoTemplated = (metaDescriptionEl.content.indexOf("%search_derivative%") > -1);

    const pageDescription = metaTagIsSeoTemplated
        ? ListingsHelpers.transformTemplatedSeoPageMetaDescription(searchPerformed,defaultVehicleTypeDisplay, metaDescriptionEl.content)
        : ListingsHelpers.getPageMetaDescription(seoTown, seoCounty, siteTitle, searchPerformed,defaultVehicleTypeDisplay)

    $('meta[name="description"]').attr('content', pageDescription);
}

public static transformTemplatedSeoPageTitleTag(searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string, templatedSeoPageTitle: string): string {
    const seoVehicleTitleText = ListingsHelpers.getFullSeoVehicleTitleText(searchPerformed,defaultVehicleTypeDisplay);

    return templatedSeoPageTitle.replace("%search_derivative%", seoVehicleTitleText);
}

public static getPageTitleTag(searchPerformed: Models.VehicleSearchPerformed,defaultVehicleTypeDisplay: string, town: string, county: string, siteTitle: string): string {

    let prefix = `${(searchPerformed.vehicleStatus  == "new" ? "New ": "Used ")}`;

    let suffix = ` for sale in ${town}, ${county} | ${siteTitle}`;

    return ListingsHelpers.getFullSeoVehicleTitleText(searchPerformed, defaultVehicleTypeDisplay, prefix, suffix,);
};

public  static updatePageTitle(contentTitleEl: JQuery<HTMLElement>, searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string, availability: string, resultsCount: number) {
    const titleIsSeoTemplated = (contentTitleEl[0].textContent != null && contentTitleEl[0].textContent.indexOf("%search_derivative%") > -1);
    const prefix = contentTitleEl[0].getAttribute("data-prefix")?.replace("{count}", resultsCount.toString()) ?? (searchPerformed.vehicleStatus == "new" ? "New " : "Used " );
    const suffix = contentTitleEl[0].getAttribute("data-suffix")?.replace("{count}", resultsCount.toString());

    const pageTitle = titleIsSeoTemplated
        ? ListingsHelpers.transformTemplatedSeoPageTitle(searchPerformed, defaultVehicleTypeDisplay, contentTitleEl[0].textContent ?? "")
        : ListingsHelpers.getPageTitle(searchPerformed, defaultVehicleTypeDisplay, prefix, suffix);

    contentTitleEl[0].textContent = pageTitle;

    contentTitleEl[0].classList.remove("hide");
}

public static updateTitleTag(town: string, county: string, siteTitle: string, searchPerformed: Models.VehicleSearchPerformed,defaultVehicleTypeDisplay: string) {
    const titleTagEL = document.querySelector("title") as HTMLTitleElement;

    const titleTagIsSeoTemplated = (titleTagEL.textContent != null && titleTagEL.textContent.indexOf("%search_derivative%") > -1);

    const pageTitle = titleTagIsSeoTemplated
        ? ListingsHelpers.transformTemplatedSeoPageTitleTag(searchPerformed, defaultVehicleTypeDisplay, titleTagEL.textContent ?? "")
        : ListingsHelpers.getPageTitleTag(searchPerformed,defaultVehicleTypeDisplay, town, county, siteTitle);

    titleTagEL.textContent = pageTitle;
}

public  static async convertQueryStringToVehicleSearchPerformed(qs: { [index: string]: string }, availability: Models.Availability, vehicleStatus: Models.VehicleStatus, defaultVehicleTypeDisplay: string): Promise<Models.VehicleSearchPerformed> {
    let makeDisplay = '';
    let modelDisplay = '';
    const termsOptions = new Models.GetSearchTermsOptions(vehicleStatus, qs.vehicletype.replace(/s$/, ""));
    const makesTask = (qs.make != null && qs.make.length > 0) ? Api.Vehicles.getMakes(new Models.GetMakesOptions(vehicleStatus, qs.vehicletype.replace(/s$/, ""))) : Promise.resolve([] as Models.VehicleCount[]);
    const modelsTask = (qs.make != null && qs.make.length > 0) ? Api.Vehicles.getModels(new Models.GetModelsOptions(vehicleStatus, qs.vehicletype.replace(/s$/, ""), qs.make)) : Promise.resolve([] as Models.VehicleCount[]);
    const additionalSearchFieldsTask = (
        qs.fueltype != null && qs.fueltype.length > 0 ||
        qs.body != null && qs.body.length > 0 ||
        qs.gearboxtype != null && qs.gearboxtype.length > 0 ||
        qs.colour != null && qs.colour.length > 0 ||
        qs.berth != null && qs.berth.length > 0 ||
        qs.doors != null && qs.doors.length > 0 ||
        qs.engine != null && qs.engine.length > 0 ||
        qs.minEngineSize != null && qs.minEngineSize.length > 0 ||
        qs.maxEngineSize != null && qs.maxEngineSize.length > 0 ||
        qs.insuranceGroup != null && qs.insuranceGroup.length > 0 ||
        qs.keywords != null && qs.keywords.length > 0 ||
        qs.minMpg != null && qs.minMpg.length > 0 ||
        qs.maxMpg != null && qs.maxMpg.length > 0 ||
        qs.minLength != null && qs.minLength.length > 0 ||
        qs.maxLength != null && qs.maxLength.length > 0 ||
        qs.minUnladenWeight != null && qs.minUnladenWeight.length > 0 ||
        qs.maxUnladenWeight != null && qs.maxUnladenWeight.length > 0 ||
        qs.year != null && qs.year.length > 0 ||
        qs.vehicles != null && qs.vehicles.length > 0 ||
        qs.branch != null && qs.branch.length > 0
    ) ? Api.Vehicles.getSearchTerms(termsOptions) : Promise.resolve([] as Models.SearchTermSpec[]);

    var makesSelected = (await makesTask)
        .filter(m => m.makeValue == qs.make);

    if (makesSelected.length > 0) {
        makeDisplay = makesSelected[0].makeName ?? "";
        var modelsSelected = (await modelsTask)
            .filter(m => m.modelValue == qs.model);
        if (modelsSelected.length > 0) {
            modelDisplay = modelsSelected[0].modelName ?? "";
        }
    }

    const vehicleTypeDisplay = (
        qs.vehicletype != undefined &&
        qs.vehicletype != null &&
        (["cars","vans","motorhomes","bikes","caravans"].indexOf(qs.vehicletype) > -1)
    )
        ? qs.vehicletype[0].toUpperCase() + qs.vehicletype.slice(1)
        : defaultVehicleTypeDisplay;

    const searchFields = await additionalSearchFieldsTask;

    const getSearchTermDisplayValue = (searchFieldName: string, searchFieldValue: string): (string | null) => {

        if(searchFieldValue == null || searchFieldValue.length == 0) {
            return null;
        }

        const matchingFieldSearchTermFieldNames = searchFields
            .filter(fld => fld.fieldName == searchFieldName)
            .map(fld => fld.acceptedSearchTerms.filter((t) => t.value == searchFieldValue))
            .reduce((names: string[], arr) => names.concat((arr.length > 0) ? arr[0].name : ""), []) as string[];

        return (matchingFieldSearchTermFieldNames.length > 0)
            ? matchingFieldSearchTermFieldNames[0]
            : null;
    };

    return new Models.VehicleSearchPerformed(
        qs.type,
        qs.vehicletype,
        vehicleTypeDisplay,
        qs.fueltype,
        getSearchTermDisplayValue("fuel_type", qs.fueltype) || '',
        qs.make,
        makeDisplay,
        qs.model,
        modelDisplay,
        qs.body,
        getSearchTermDisplayValue("body_type", qs.body) || '',
        qs.gearboxtype,
        getSearchTermDisplayValue("transmission", qs.gearboxtype) || '',
        qs.colour,
        getSearchTermDisplayValue("basic_colour", qs.colour) || '',
        qs.berth,
        qs.doors,
        qs.engine,
        qs.minEngineSize,
        qs.maxEngineSize,
        qs.insuranceGroup,
        qs.keywords,
        qs.minMpg,
        qs.maxMpg,
        qs.minLength,
        qs.maxLength,
        qs.minUnladenWeight,
        qs.maxUnladenWeight,
        qs.year,
        qs.vehicles,
        qs.branch,
        availability,
        vehicleStatus
    );
}

public static synchronizeSearchInputs() {
    $('#searchFilters').on("submit", function () {
        var searchFiltersForm = $('#searchFilters');
        var searchBoxItems = $('#searchBox select');

        for (var i = 0; i < searchBoxItems.length; i++) {
            var searchBoxItem = searchBoxItems[i] as HTMLSelectElement;
            var searchEl = '<input type="hidden" name="' + searchBoxItem.name + '" value="' + searchBoxItem.value + '" />';
            searchFiltersForm.append(searchEl);
        }

        return true;
    });
}

public static setSearchResultUrls(adDetailsUrls: string[] | null)
{    
        const searchUrlListKey = "searchResultPaths";
        if (adDetailsUrls == null || adDetailsUrls.length == 0)
        {
            sessionStorage.removeItem(searchUrlListKey);
        }
        sessionStorage.setItem(searchUrlListKey,JSON.stringify(adDetailsUrls));
}



public static generateSearchResultsHtml(searchResults: Models.VehicleSearchResult, pageConfig:PageConfig, rootSearchUrl: string, financeConfig: FinanceConfig, dealerBranches: DealerBranchPublicInfo[], templateId: string = "searchResultsTemplate", imgPlaceholder: string = null) {
    
    let items = searchResults.results;

    // Cache of the template
    let template = document.getElementById(templateId);

    // Get the contents of the template
    let templateHtml = template?.innerHTML ?? "";
    // Final HTML variable as empty string
    let listHtml = "";

    if(items.length == 0) {
        const sr = document.getElementById("searchResults");

        if(sr != null) {
            sr.innerHTML = document.getElementById("noStockTemplate")?.innerHTML ?? "";
        }

        return;
    }

    // regex creation is reasonably expensive, cache the regex built for repeated templates like this
    let cachedRegEx: RegExp | null = null;
    const regExCacher = function (regex: RegExp) {
        // return the cachced regex if no regex is supplied, or cache it and return it if it is supplied
        if (regex !== null) {
            cachedRegEx = regex;
        }
        return cachedRegEx;
    };    
    if(items.length > 0) {
        // Loop through items, replace placeholder tags
        // with actual data, and generate final HTML
        for (var i = 0; i < items.length; i++) {
            var vehicle = items[i];            

            const gallery = ListingsHelpers.getThumbs(vehicle,"main");
            const isSaved = ListingsHelpers.checkIfSaved(vehicle.id);
            let saveEmClass = (isSaved) ? "fas" : "far";
            let saveSectionExtraClasses = (isSaved) ?" saved-button success" : "";
            let saveText = (isSaved) ? "Saved" : "Save";
            var save: string = `<section class="compare-button button carlist${saveSectionExtraClasses}" data-vehId="${vehicle.id}"><i class="${saveEmClass} fa-star" aria-hidden="true"></i><span> ${saveText}</span></section>`;
            var saveMobile: string = `<section class="compare-button button mobile-only carlist${saveSectionExtraClasses}" data-vehId="${vehicle.id}"><i class="${saveEmClass} fa-star" aria-hidden="true"></i><span></span></section>`;

            var description: string = (vehicle.description !== null && vehicle.description !== 'None' && typeof vehicle.description !== 'undefined')
                ? (vehicle.description.length < 251)
                    ? "<div class=\"l-node-used-vehicle--search-result__additional\">" + vehicle.description + "</div>"
                    : "<div class=\"l-node-used-vehicle--search-result__additional\">" + vehicle.description.substring(0, 250) + "...</div>"
                : "";

            var youtube: string = (vehicle.youtube_link !== null && vehicle.youtube_link !== 'None' && typeof vehicle.youtube_link !== 'undefined')
            ? '<i class="fas fa-video"></i> 1'
            : "";



            var socialContent = `${vehicle.year_built} ${vehicle.manufacturer_display} ${vehicle.model_display} ${StringFormatting.coerceNullishToBlank(vehicle.derivative_display)}, ${VehicleStringFormatting.price(vehicle.price, vehicle.sold)} ${window.location.protocol}//${ window.location.hostname}${vehicle.urlPath}`
            var vehicleTypeForSearch = (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() + 's' : '';

            // string replacements to perform
            var fuelTypeSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); fuelTypeSearch.fuelType = vehicle.fuel_type;
            var bodyTypeSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); bodyTypeSearch.bodyType = vehicle.body_type;
            var transmissionSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); transmissionSearch.gearboxType = vehicle.transmission;
            var manufacturerSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); manufacturerSearch.make = vehicle.manufacturer;
            var modelSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); modelSearch.make = vehicle.manufacturer; modelSearch.model = vehicle.model;
            var colourSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); colourSearch.colour = vehicle.basic_colour;
            var firstDealerBranch = dealerBranches.filter(b=>b.id == vehicle.branch_id).pop();

            var replacements = {
                '%vehicle_id%': vehicle.id,
                '%vehicle_year%': StringFormatting.coerceNullishToBlank(vehicle.year_built),
                '%vehicle_type%': (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() : '',
                '%vehicle_type_plural%': (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() + 's' : '',
                '%vehicle_make%': vehicle.manufacturer,
                '%vehicle_make_display%': vehicle.manufacturer_display,
                '%vehicle_model%': vehicle.model,
                '%vehicle_model_display%': vehicle.model_display,
                '%vehicle_derivative%': StringFormatting.coerceNullishToBlank(vehicle.derivative),
                '%vehicle_derivative_display%': StringFormatting.coerceNullishToBlank(vehicle.derivative_display),
                '%vehicle_price%': VehicleStringFormatting.price(vehicle.price, vehicle.sold),
                '%vehicle_list_price%': VehicleStringFormatting.listPrice(vehicle.list_price, vehicle.sold, ""),
                '%vehicle_discount_amount%': VehicleStringFormatting.savingPrice(vehicle.discount_amount, vehicle.sold, ""),
                '%vehicle_discount_price%': VehicleStringFormatting.price(vehicle.discount_price, vehicle.sold, ""),
                '%vehicle_photocount%': vehicle.imageCount.toString(),
                '%vehicle_photo%': gallery,
                '%vehicle_thumbs%': buildVehicleImageThumbnails(vehicle.images, pageConfig.number_of_extra_images),
                '%vehicle_thumbs_slick%': buildVehicleImageThumbnailsSlick(vehicle.images, pageConfig.number_of_extra_images),
                '%vehicle_mileage%': (vehicle.mileage !== null) ? StringFormatting.numberWithCommas(vehicle.mileage ?? 0) : '',
                '%vehicle_engine%': StringFormatting.coerceNullishToBlank(vehicle.engine),
                '%vehicle_engine_link%': '<a href="' + Page.getSearchUrl(fuelTypeSearch, true) + '">' + vehicle.engine + '</a>',
                '%vehicle_gearbox%': StringFormatting.coerceNullishToBlank(vehicle.gearbox),
                '%vehicle_gearbox_link%': '<a href="' + Page.getSearchUrl(transmissionSearch, true) + '">' + vehicle.gearbox + '</a>',
                '%vehicle_capacity%': VehicleStringFormatting.engineSize(vehicle.engine_capacity),
                '%vehicle_insurance%': StringFormatting.coerceNullishToBlank(vehicle.insurance_group),
                '%vehicle_mpg%': VehicleStringFormatting.mpg(vehicle.mpg),
                '%vehicle_length%': StringFormatting.coerceNullishToBlank(vehicle.length),
                '%vehicle_unladen_weight%': StringFormatting.coerceNullishToBlank(vehicle.unladened_weight),
                '%vehicle_co2%': VehicleStringFormatting.co2(vehicle.co2),
                '%vehicle_tax%': VehicleStringFormatting.taxRate(vehicle.tax_rate_12),
                '%vehicle_branch%': StringFormatting.coerceNullishToBlank(vehicle.branch_name),
                '%vehicle_branch_details_custom%': (firstDealerBranch != null) ? VehicleStringFormatting.generateVehicleBranchDetailsCustomHtml(firstDealerBranch) : "",
                '%vehicle_additional%': description,
                '%vehicle_make_url%': Page.getSearchUrl(manufacturerSearch, true),
                '%vehicle_make_link%': '<a href="' + Page.getSearchUrl(manufacturerSearch, true) + '">' + vehicle.manufacturer_display + '</a>',
                '%vehicle_model_link%': '<a href="' + Page.getSearchUrl(modelSearch, true) + '">' + vehicle.model_display + '</a>',
                '%vehicle_sash%': VehicleStringFormatting.sash((vehicle.reserved ==true ? "Reserved": vehicle.vehicle_sash)),
                '%vehicle_save%': save,
                '%vehicle_whatsapp%': encodeURIComponent(socialContent),
                '%vehicle_twitter%' : encodeURIComponent(socialContent),
                '%vehicle_youtube%': youtube,
                '%vehicle_save_mobile%': saveMobile,
                '%vehicle_vat_exempt%': VehicleStringFormatting.vatExempt(vehicle.vat_exempt),
                '%vehicle_vat_excluded%': VehicleStringFormatting.vatExcluded(vehicle.vat_excluded),
                '%vehicle_height%' : VehicleStringFormatting.millimeters(vehicle.height),
                '%vehicle_internal_length%' : VehicleStringFormatting.millimeters(vehicle.internal_length),
                '%vehicle_berth%' : StringFormatting.coerceNullishToBlank(vehicle.berth),
                '%vehicle_unladened_weight%': VehicleStringFormatting.kilogrammes(vehicle.unladened_weight),
                '%vehicle_mtplm%' : VehicleStringFormatting.kilogrammes(vehicle.mtplm),
                '%vehicle_category%': StringFormatting.coerceNullishToBlank(vehicle.category),
                '%vehicle_seats%': StringFormatting.coerceNullishToBlank(vehicle.seats),
                '%vehicle_exhaust_manufacturer%': StringFormatting.coerceNullishToBlank(vehicle.exhaust_manufacturer),
                '%vehicle_muffler%': StringFormatting.coerceNullishToBlank(vehicle.muffler),
                '%vehicle_body_link%': StringFormatting.coerceNullishToBlank(vehicle.body_name, `<a href="${Page.getSearchUrl(bodyTypeSearch, true)}">${vehicle.body_name}</a>`),
                '%vehicle_finance_quotes%' : '',
                "%vehicle_details_url%": vehicle.urlPath,
                "%vehicle_manufacturers_warranty_expiry%": StringFormatting.dateToString(vehicle.manufacturers_warranty_expiry),
                '%vehicle_monthly_payment_link%': VehicleFinanceQuotes.setVehicleMonthlyPayment(vehicle),
                "%vehicle_finance_checker_url%": VehicleFinanceChecker.GetFinanceCheckerUrl(financeConfig,vehicle),
                "%vehicle_vrm%": vehicle.vrm,
                "%vehicle_mot_expiry%":  StringFormatting.dateToString(vehicle.mot_expiry),
                "%vehicle_colour%": vehicle.colour,
                "%vehicle_body%" : vehicle.body_name,
                "%vehicle_colour_link%": '<a href="' + Page.getSearchUrl(colourSearch, true) + '">' + vehicle.colour + '</a>',
                "%vehicle_reserved%": vehicle.reserved == true ?"true": "false",
                "%vehicle_reg_letter%": StringFormatting.stringInBrackets(vehicle.reg_letter),
                "%vehicle_interior_colour%": StringFormatting.coerceNullishToBlank(vehicle.interior_colour),
                '%vehicle_gallery%': ListingsHelpers.generateGallery(vehicle.images, pageConfig.number_of_extra_images)

            };
            // do replacements (using regex caching)
            listHtml += QuickReplace.quickReplace(replacements, templateHtml, regExCacher);
        }        
        // create document fragment so we can adjust using DOM before visibly rendering
        const dummyEl = document.createElement("span");
        DOMPurify.addHook('afterSanitizeAttributes', function(node) {
            // set all elements owning target to target=_blank
            if ("target" in node ==true)
            {
                let anchorNode = node as HTMLAnchorElement;
                if (anchorNode.target.length > 0 && anchorNode.target !== "_self")
                {
                node.setAttribute('target','_blank');
                // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
                node.setAttribute('rel', 'noopener noreferrer');
                }
            }
        });

        dummyEl.innerHTML = DOMPurify.sanitize(listHtml,{ADD_ATTR: ['target']});


        // remove empty overview list items
        ListingsHelpers.removeEmptySpecListEls(dummyEl.querySelectorAll(".specs-list"));

        //Remove cta's from reserved vehicles
        [].forEach.call(dummyEl.querySelectorAll("[data-hidewhenreserved=true]"),(reservedCta: HTMLElement)=> {reservedCta.remove();});

        // Replace the HTML of #searchResults with final HTML
        const sr = document.getElementById("searchResults");
        if(sr != null) {
            sr.innerHTML = dummyEl.innerHTML;
        }

        let  vehicleWithFinance = items.filter(f=> f.finance_quotes != null && f.finance_quotes !=null && f.finance_quotes.length >0)[0];
        if (vehicleWithFinance)
        {
            let finance_representative_example_div = document.getElementById("finance_representative_example");
            if (finance_representative_example_div)
            {
                finance_representative_example_div.innerHTML = DOMPurify.sanitize(VehicleFinanceQuotes.setVehicleFinanceQuotes(vehicleWithFinance.finance_quotes),{ADD_ATTR: ['target']});
            }
        }

    }
    //find finance_representative_example

    $(".compare-button").on("click", (evt) => {
        evt.preventDefault();
        let btnEl = (evt.target.classList.contains("compare-button")) ? evt.target : $(evt.target).parents(".compare-button:first")[0]
        const vehId = btnEl.getAttribute("data-vehId");
        if(vehId != null) {
            const error = VehicleComparison.toggleVehicleCompare(vehId);
            if (error != null) {
                console.log(error);
            }

            Common.updateSavedVehiclesCount();

            ListingsHelpers.toggleVehicleCompareButtonState(ListingsHelpers.checkIfSaved(vehId), btnEl);
        }
    });
}


public static initializeDefaultPager(totalPages: number, pageNumber: number, pageSize: number, baseUrl: string) {
    var els = $('.search-pager').find('.pager');

    if (els.length > 0) {
        var unpagedUrl = document.location.pathname;

        for (var i = 0; i < els.length; i++) {
            SearchPager.init(els[i] as HTMLUListElement, pageNumber, totalPages, pageSize, 9, baseUrl, unpagedUrl);
        }
    }
}

public static initializePager(totalPages: number, pageNumber: number, pageSize: number | null, sortBy: string | null, sortOrder: string | null, baseUrl: string, rootSearchUrl: string) {
    var els = $('.search-pager').find('.pager');

    if (els.length > 0) {
        var search = new Models.VehicleSearch(rootSearchUrl);
        search.vehicleType = Page.queryString['vehicletype'];
        search.make = Page.queryString['make'];
        search.model = Page.queryString['model'];
        search.minPrice = Page.queryString['minprice'];
        search.maxPrice = Page.queryString['maxprice'];
        search.bodyType = Page.queryString["body"];
        search.colour = Page.queryString["colour"];
        search.fuelType = Page.queryString["fueltype"];
        search.gearboxType = Page.queryString["gearboxtype"];
        search.gearbox = Page.queryString["gearbox"];

        search.berth = Page.queryString["berth"];
        search.doors = Page.queryString["doors"];
        search.minEngineSize = Page.queryString["min-engine-size"];
        search.maxEngineSize = Page.queryString["max-engine-size"];
        search.insuranceGroup = Page.queryString["insurance-group"];
        search.keywords = Page.queryString["keywords"];
        search.minMpg = Page.queryString["min-mpg"];
        search.maxMpg = Page.queryString["max-mpg"];
        search.minLength = Page.queryString["min-length"];
        search.maxLength = Page.queryString["max-length"];
        search.minUnladenWeight = Page.queryString["min-unladen-weight"];
        search.maxUnladenWeight = Page.queryString["max-unladen-weight"];
        search.year = Page.queryString["year"];
        search.vehicles = Page.queryString["vehicles"];
        search.branch = Page.queryString["branch"];

        search.sortBy = sortBy ?? '' ;
        search.sortOrder = sortOrder ?? '';
        search.minMonthlyPrice = Page.queryString["minmonthlypayment"]
        search.maxMonthlyPrice = Page.queryString["maxmonthlypayment"]
        var relativeUnpagedUrl = Page.getSearchUrl(search);

        for (var i = 0; i < els.length; i++) {
            SearchPager.init(els[i] as HTMLUListElement, pageNumber, totalPages, pageSize, 9, baseUrl, relativeUnpagedUrl);
            
        }
    }
}

public static checkIfSaved(vehicleID: string) {
    return VehicleComparison.isVehicleInComparisonList(vehicleID);
}

public static toggleVehicleCompareButtonState(isSaved: boolean, compareBtnEl: HTMLElement) {
    const isMobileView = compareBtnEl.classList.contains("mobile-only");

    let saveSectionExtraClasses = "saved-button success";
    if (isSaved) {
        $(compareBtnEl)
            .addClass(saveSectionExtraClasses)
            .children("i").addClass("fas").removeClass("far");

        if (!isMobileView) {
            $(compareBtnEl).children("span")[0].textContent = " Saved";
        }
    } else {
        $(compareBtnEl)
            .removeClass(saveSectionExtraClasses)
            .children("i").addClass("far").removeClass("fas");

        if (!isMobileView) {
            $(compareBtnEl).children("span")[0].textContent = " Save";
        }
    }
}

// public static getPlaceholder(vehicleType: string) {
//     if(vehicleType == 'van') {
//         return "<div class='awaiting-image'><img src='/basemedia/commercial.png'></div>";
//     }
//     else {
//         return "<div class='awaiting-image'><img src='/basemedia/" + vehicleType + ".png'></div>"
//     }
// }


public static updatePageTitleAndMetaWhenReady(siteConfig: SiteConfig, searchPerformed: Models.VehicleSearchPerformed, defaultVehicleTypeDisplay: string, availability: Models.Availability, elSelector: string, resultsCount: number, crumbs: Models.BreadcrumbEntry[] ) {
    const whenReady: FrameRequestCallback = (timestamp: number) => {
        const el = document.querySelector(elSelector) as HTMLElement;

        if (el == null) {
            window.requestAnimationFrame(whenReady);
        } else {
            ListingsHelpers.updateTitleTag(siteConfig.seoTown, siteConfig.seoCounty, siteConfig.siteTitle, searchPerformed,defaultVehicleTypeDisplay);

            ListingsHelpers.updatePageMetaDescription(siteConfig.seoTown, siteConfig.seoCounty, siteConfig.siteTitle, searchPerformed,defaultVehicleTypeDisplay);

            Breadcrumbs.setBreadcrumbs(crumbs);

            ListingsHelpers.updatePageTitle($('main header h1'), searchPerformed, defaultVehicleTypeDisplay,  availability, resultsCount);
        }
    }

    window.requestAnimationFrame(whenReady);
}

public static setHiddenFilterOptions(minPrice: number|null|undefined, maxPrice: number|null|undefined, minMonthlyPayment?: number| null, maxmonthlyPayment?: number| null) {
    let searchFiltersEl = document.querySelector("#searchFilters");

    if(searchFiltersEl == null) {
        return;
    }

    let minPriceEl = searchFiltersEl.querySelector('input[name="minprice"]') as HTMLInputElement;
    let maxPriceEl = searchFiltersEl.querySelector('input[name="maxprice"]') as HTMLInputElement;
    let minMonthlyPriceEl = searchFiltersEl.querySelector('input[name="minmonthlypayment"]') as HTMLInputElement;
    let maxMonthlyPriceEl = searchFiltersEl.querySelector('input[name="maxmonthlypayment"]') as HTMLInputElement;


    minPriceEl.value = (minPrice != null) ? minPrice.toString() : "";
    maxPriceEl.value = (maxPrice != null) ? maxPrice.toString() : "";

    if (minMonthlyPriceEl)
    {
        minMonthlyPriceEl.value = (minMonthlyPayment !=null ? minMonthlyPayment.toString(): "");
    }
    if (maxMonthlyPriceEl)
    {
        maxMonthlyPriceEl.value = (maxmonthlyPayment !=null ? maxmonthlyPayment.toString(): "");
    }

}

private static generateGallery (images:VehicleImageUrls[], numberOfExtraImages:number) {


    if (images == null || images.length == 0) {
        return '<img class="main-img" src="/media/placeholder.jpg">';
    }
    if ( numberOfExtraImages == null || numberOfExtraImages == 0) {
        return `<img class="main-img" src="${images[0].i800x600}" data-placeholder="/media/placeholder.jpg">`;
    }    
      let galleryImages = images.slice(0,numberOfExtraImages)
                              .map(im=> `<li><img src="${im.i800x600}" data-placeholder="/media/placeholder.jpg"></li>`)
                              .join("");
      let gallery = `<ul class="slick slick--vehicle-results-gallery">${galleryImages}</ul>`;
      return gallery;
}


public static initGallery() {
    console.log($('.slick--results-gallery-main').length);
    console.log($('.slick--results-gallery-main'));
    if ($('.slick--results-gallery-main').length > 0) {
      $('.slick--results-gallery-main').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true,
        autoplay: false
      });
    }
  }

private static getThumbs(vehicle: Models.Vehicle,gallerytype: string) {
    if (gallerytype == "main") {
      if (vehicle.images.length == 0) {
        return "<div class='awaiting-image'><img src='/basemedia/placeholder.jpg'></div>";
      }
      else {
        let thumbs = `<ul class="slick slick--results-gallery-${gallerytype}">`;
        for (let i = 0; i< ( vehicle.images.length >=10 ? 10:  vehicle.images.length); i++) {
          thumbs += `<li><img src="${vehicle.images[i].i800x600}" class=""></li>`;
        }
        thumbs += `</ul>`;
        return thumbs;
      }
    }

  }

}